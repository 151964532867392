<template>
  <div id="app">
    Evalarm Console!
  </div>
</template>

<script>
export default {
  name: 'Application',
};
</script>
